import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'

import Content from '../components/Merry/Project/Content'
import { rhythm, scale } from '../utils/typography'
import PageWrap from '../components/PageWrap'

import loadJuxtaposes from '../utils/post/juxtapose'

interface Props {
  data: {
    markdownRemark: any
    site: {
      siteMetadata: {
        siteUrl: string
        title: string
      }
    }
  }
  pageContext: any
  location: any
}

const BlogPostTemplate = ({ data, pageContext, location }: Props) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const navStartsOpen = location.state && location.state.navOpen !== undefined? location.state.fromFeed: true

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    loadJuxtaposes()
  })

  return (
    <PageWrap
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
      navOpen={navStartsOpen}
    >
        <Content>
        <h1
          style={{
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          {post.frontmatter.title}
        </h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
          }}
        >
          {post.frontmatter.date}
        </p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li style={{maxWidth: '40%'}}>
            {previous && (
              <Link to={`/post${previous.fields.slug}`} rel="prev" state={{ navOpen: false}}>
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li style={{maxWidth: '40%'}}>
            {next && (
              <Link to={`/post${next.fields.slug}`} rel="next" state={{ navOpen: false}}>
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <Disqus config={{
          url: `${data.site.siteMetadata.siteUrl}${location.pathname}`,
          identifier: post.id,
          title: post.frontmatter.title,
        }} />
        </Content>
    </PageWrap>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`