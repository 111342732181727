import 'juxtaposejs/build/css/juxtapose.css'
import './juxtapose.css'

if (typeof window !== 'undefined') {
    require('juxtaposejs/build/js/juxtapose')
}

const justaposeSelector = "div.juxt"
const gatsbyImgSelector = ":scope img"

declare global {
    interface Window {
        juxtapose:any;
    }
}

const load = () => {
    const els = document.querySelectorAll(justaposeSelector)

    els.forEach((el: Element) => {
        addJuxtapose(<HTMLDivElement>el)
        el.setAttribute('style', 'min-width:100%;max-width:100%;')
    })
}

const addJuxtapose = (element: HTMLDivElement) => {
    const imgs = element.querySelectorAll(gatsbyImgSelector)
    const imageSrcs: string[] = []
    const imageTags: string[] = []

    imgs.forEach((e: Element) => {
        imageSrcs.push(String(e.getAttribute('src')))
        console.log(String(e.getAttribute('alt')));

        const name = String(e.getAttribute('alt'))
        if (name != '') {
            imageTags.push(name)
        } else {
            if (imageTags.length === 0) {
                imageTags.push('Before')
            } else {
                imageTags.push('After')
            }
        }
    })

    element.innerHTML = '';

    // eslint-disable-next-line
    new window.juxtapose.JXSlider(`#${element.id}`,
    [
        {
            src: imageSrcs[0],
            label: imageTags[0],
        },
        {
            src: imageSrcs[1],
            label: imageTags[1],
        }
    ],
    {
        animate: true,
        showLabels: true,
        showCredits: false,
        startingPosition: "50%",
        makeResponsive: true
    });
}

export default load
