import React from 'react'
import styled from 'styled-components'
import { SizeMD } from '../vars'

const Root = styled.div`
    max-width: 900px;
    margin: 0 auto;
    padding: 16px;
    position: relative;

@media screen and (min-width: ${SizeMD}) {
    width: calc(100% - 300px);
}
`

interface BodyProps {
    children?: any
}

const Body = ({ children }: BodyProps) => {
    return (
        <Root id="contentRoot">
            { children }
        </Root>
    )
}

export default Body
